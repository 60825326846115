import React, { Component } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { Input, InputRef, Form, FormInstance, FormRule, Tooltip } from 'antd';

import styles from './styles/input-editor.less';

interface Props extends ICellEditorParams {
    rules?: Array<FormRule> | undefined;
}

interface State {
    value: string;
    errors: string;
}

class InputEditor extends Component<Props, State> {
    
    inputRef: React.RefObject<InputRef>;
    formRef: React.RefObject<FormInstance>;

    constructor(props: Props) {
        super(props);

        this.state = {
            value: props.value,
            errors: ""
        };

        this.inputRef = React.createRef();
        this.formRef = React.createRef();
    }

    getValue() {    
        return this.state.value;
    }

    isPopup() {
        return false;
    }

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {        
        const value = e.target.value;

        this.formRef.current?.validateFields().then(() => {     
            this.setState({
                errors: "",
                value: value
            });   
        }).catch(() => {
            const fieldsError = this.formRef.current?.getFieldsError() ?? [];
            let errors = '';

            for (let i = 0; i < fieldsError.length; i++) {
                for (let x = 0; x < fieldsError[i].errors.length; x++) {
                    errors += fieldsError[i].errors[x];
                }
            } 
            
            this.setState({
                errors: errors
            });   
        });            
    }

    afterGuiAttached() {
        this.inputRef.current?.focus();
    }

    isCancelAfterEnd() {
        return this.state.errors.length !== 0;
    } 

    render() {   
        const { rules, value } = this.props;

        return (
            <Form layout="inline" ref={this.formRef} className={styles.form} initialValues={{
                input: value
            }}>
                <Tooltip title={this.state.errors} open={this.state.errors.length > 0}>
                    <Form.Item name="input" rules={rules} noStyle={true}>
                        <Input
                            ref={this.inputRef}
                            onChange={this.onChange}
                            className={styles.inputItem}
                        />
                    </Form.Item>
                </Tooltip>
            </Form>
        );
    }
}

export default InputEditor;
export { styles as inputEditorStyles }