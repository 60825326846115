import { ICellEditorParams } from 'ag-grid-community';
import { Select, RefSelectProps, Tag } from 'antd';
import { LabeledValue, SelectValue } from 'antd/es/select';
import React, { Component, CSSProperties } from 'react';
import { SelectProps, CustomTagRenderOptions } from './interfaces/select-props';

// to ensure styling is the same as the search select editor
import styles from '../search-select/styles/search-select-editor.less';

interface Props extends ICellEditorParams, SelectProps {
    getData: (selected: LabeledValue[] | LabeledValue) => LabeledValue[] | LabeledValue;
    style: CSSProperties;
    dropdownStyle: CSSProperties;
    handleChange: (value: LabeledValue[] | LabeledValue, item: SelectValue) => LabeledValue[] | LabeledValue;
    handleTagRender?: (selected: LabeledValue | LabeledValue[], props: any) => CustomTagRenderOptions | undefined;
    maxTagPlaceholder: string;
    placeholderText?: string;
    onChange?: () => void;
}

interface State {
    selected: LabeledValue[] | LabeledValue;
    items: LabeledValue[] | LabeledValue;
}

class SelectEditor extends Component<Props, State> {
    selectRef: React.RefObject<RefSelectProps>;

    constructor(props: Props) {
        super(props);
        
        this.state = {
            selected: props.value === null ? undefined : props.value,
            items: [],
        };

        this.selectRef = React.createRef();
    }

    componentDidMount() {
        this.getSelectData(this.state.selected);
    }

    getValue() {
        return this.state.selected;
    };

    isPopup() {
        return false;
    };

    onSelect = (selected: LabeledValue[] | LabeledValue) => {
        this.setState({ selected });
    };

    afterGuiAttached() {
        this.selectRef.current?.focus();
    }

    isCancelAfterEnd() {
        return (this.state.selected === null)
    };

    getSelectData = (selected: LabeledValue[] | LabeledValue) => {
        const data = this.props.getData ? this.props.getData(selected) : [];

        this.setState({
            items: data
        });
    };

    handleChange = (item: SelectValue, option: any) => {
        if (!Array.isArray(option)) {
            this.setState({ selected: { label: option.children, value: option.value } });
        }
        else {
            let selected: SelectValue = option.map((item) => {
                return { label: item.children, value: item.value };
            });

            if (this.props.handleChange) {
                selected = this.props.handleChange(selected, item);
            }
            this.setState({
                selected: selected
            });
            this.getSelectData(selected);
        }

        if (this.props.onChange) {
            this.props.onChange();
        }
    };

    convertValue(value: LabeledValue[] | LabeledValue): any {
        if (value === undefined) {
            return undefined;
        }
        else if (typeof value === "string" || typeof value === "number") {
            return value;
        }
        else if (Array.isArray(value)) {
            if (value.length > 0)
                return value.map((v) => v.value);
            else
                return undefined;
        }
        else if (value.value !== "default") {
            return value.value;
        } else {
            return undefined;
        }
    };

    tagRender = (props: any) => {
        const { label, closable, onClose } = props;

        let renderOptions: CustomTagRenderOptions = {
            label: label,
            show: true,
        }

        if (this.props.handleTagRender) {
            const customRender = this.props.handleTagRender(this.state.selected, props);
            if (customRender) {
                renderOptions = customRender;
            }
        }

        return (
            (renderOptions.show) ?
                <Tag closable={closable} onClose={onClose} className={styles.selectTagEditor}>
                    {renderOptions.label}
                </Tag>
            : <span></span>
        );
    };

    render() {       
        const { style, dropdownStyle, mode, maxTagCount, placeholderText } = this.props;
        const { items, selected } = this.state;

        const isLabeledValueArray = (items: any): items is LabeledValue[] =>
            (items as LabeledValue[]) !== undefined;

        return (          
            <Select
                ref={this.selectRef}
                showAction={['focus', 'click']}
                style={style}
                dropdownStyle={dropdownStyle}
                mode={mode}
                className={styles.select + (mode === "multiple" ? (" " + styles.selectMultiple) : "")}
                tagRender={this.tagRender}
                popupClassName={styles.selectDropdown}
                onChange={this.handleChange}
                defaultValue={this.convertValue(selected)}
                maxTagCount={maxTagCount}
                maxTagPlaceholder={this.props.maxTagPlaceholder}
                placeholder={placeholderText}
            >
                {
                (items && isLabeledValueArray(items)) ?
                    items.map((item: LabeledValue, index: number) =>
                        <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
                        ) : ""
                }
            </Select>
        );
    }
}

export default SelectEditor;
