import { ICellEditorParams } from 'ag-grid-community';
import React, { Component } from 'react';
import { SearchSelectItem, SearchSelectPropsBase } from '.';
import SearchSelect from './search-select';
import styles from './styles/search-select-editor.less';


interface Props extends ICellEditorParams, SearchSelectPropsBase {
    getEditorData?: (params: ICellEditorParams) => any; //Pass through editor params if needed.
}

interface State {
    selected: Array<SearchSelectItem> | SearchSelectItem;
}

class SearchSelectEditor extends Component<Props, State> {
    selectRef: React.RefObject<SearchSelect>;

    constructor(props: Props) {
        super(props);

        this.state = {
            selected: props.value === null ? undefined : props.value 
        };

        this.selectRef = React.createRef();
    }

    getValue() {
        return this.state.selected;
    }

    isPopup() {
        return false;
    }

    onSelect = (selected: Array<SearchSelectItem> | SearchSelectItem) => {
        this.setState({ selected });
    }

    afterGuiAttached() {       
        this.selectRef.current?.focus();
    }

    isCancelAfterEnd() {
        return (this.state.selected === null)
    }

    render() {       
        const { action, controller, getData, emptyDescription, showSearch, getEditorData, mode, maxTagCount } = this.props;

        return (          
            <SearchSelect               
                ref={this.selectRef}
                defaultValue={this.state.selected}
                mode={mode}
                showSearch={showSearch}
                action={action}
                controller={controller}
                getData={getEditorData !== undefined ? () => getEditorData(this.props) : getData}
                emptyDescription={emptyDescription}
                onChange={this.onSelect}
                className={styles.select + (mode === "multiple" ? (" " + styles.selectMultiple) : "")}
                popupClassName={styles.selectDropdown}
                maxTagCount={maxTagCount}
                />
        );
    }
}

export default SearchSelectEditor;