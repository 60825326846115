import { Button, Checkbox, Empty, Tree, TreeDataNode } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { Component, Key } from 'react';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import styles from './styles/tree-selector.less';

interface Props {
    initialValue?: Array<Key>;
    value?: Array<Key>;
    className?: string;
    unselectedTitle?: string;
    selectedTitle?: string;
    unselectedHeader?: string;
    selectedHeader?: string;
    selectedEmpty?: string;
    unselectedEmpty?: string;
    addText: string;
    removeText: string;
    onTreeData: (values: Array<Key>, selected: boolean) => TreeDataNode[];
    onChange?: (values: Array<Key>) => void;
    disabled?: boolean;
}

interface State {
    value: Array<Key>;
    checkedUnselected: Array<Key>;
    checkedSelected: Array<Key>;
    expandedUnselected?: Array<Key>;
    expandedSelected?: Array<Key>;
    selectAll: boolean;
    unselectAll: boolean;
}

class TreeSelector extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            value: props.value ?? props.initialValue ?? [],
            checkedSelected: [],
            checkedUnselected: [],
            selectAll: false,
            unselectAll: false
        }
    }

    parseTreeData = (key: Array<Key>, selected: boolean) => {
        const { onTreeData } = this.props;
        const { expandedSelected, expandedUnselected } = this.state;

        const result = onTreeData(key, selected);

        const keys: (string | number)[] = this.getKeys(result);

        //Auto expand both trees.
        if (selected && expandedSelected === undefined) {
            //take out of render routine.
            setTimeout(() => {
                if (this.state.expandedUnselected === undefined) {
                    this.setState({ expandedSelected: keys });
                } else {
                    // TODO: Check if cast works
                    const combined = keys.concat(this.state.expandedUnselected as (string | number)[]);
                    this.setState({ expandedSelected: combined, expandedUnselected: combined });
                }
            });
        }
        else if (!selected && expandedUnselected === undefined) {
            //take out of render routine.
            setTimeout(() => {
                if (this.state.expandedSelected === undefined) {
                    this.setState({ expandedUnselected: keys });
                }
                else {
                    // TODO: Check if cast works
                    const combined = keys.concat(this.state.expandedSelected as (string | number)[]);
                    this.setState({ expandedSelected: combined, expandedUnselected: combined });
                }
            });
        }

        return result;
    }

    getKeys = (nodes: TreeDataNode[]) => {
        const result: Array<string | number> = [];

        for (const node of nodes) {
            if (node.children !== undefined) {
                // TODO: Check if cast works
                result.push(node.key as string | number);
                result.push(...this.getKeys(node.children));
            }
        }

        return result;
    }

    handleCheckUnselected = (checked: Key[] | { checked: Key[]; halfChecked: Key[]; }) => {
        if (Array.isArray(checked))
            this.setState({ checkedUnselected: checked, selectAll: false });
        else
            this.setState({ checkedUnselected: checked.checked, selectAll: false });
    }

    handleCheckSelected = (checked: Key[] | { checked: Key[]; halfChecked: Key[]; }) => {
        if (Array.isArray(checked))
            this.setState({ checkedSelected: checked, selectAll: false });
        else
            this.setState({ checkedSelected: checked.checked, selectAll: false });
    }

    handleExpandUnselected = (expanded: Key[]) => {
        this.setState({ expandedUnselected: expanded });
    }

    handleExpandSelected = (expanded: Key[]) => {
        this.setState({ expandedSelected: expanded });
    }

    handleSelect = () => {
        let value: Key[];

        if (this.props.value !== undefined)
            value = this.props.value;
        else
            value = this.state.value;

        const { checkedUnselected } = this.state;
        const { onChange } = this.props;

        while (checkedUnselected.length > 0) {
            const item = checkedUnselected.pop();

            if (item !== undefined)
                value.push(item);
        }

        this.setState({ value, checkedUnselected, unselectAll: false, selectAll: false });

        if (onChange !== undefined) {
            onChange(value);
        }
    }

    handleDeselect = () => {
        let value: Key[];

        if (this.props.value !== undefined)
            value = this.props.value;
        else
            value = this.state.value;

        const { checkedSelected } = this.state;
        const { onChange } = this.props;

        while (checkedSelected.length > 0) {
            const item = checkedSelected.pop();

            if (item !== undefined) {
                const index = value.indexOf(item);

                if (index > -1)
                    value.splice(index, 1);
            }
        }

        this.setState({ value, checkedSelected, unselectAll: false, selectAll: false });

        if (onChange !== undefined) {
            onChange(value);
        }
    }

    handleUnselectedAll = (e: CheckboxChangeEvent) => {
        let value: Key[];

        if (this.props.value !== undefined)
            value = this.props.value;
        else
            value = this.state.value;

        if (e.target.checked) {
            this.setState({ checkedUnselected: this.getLeafKeys(this.parseTreeData(value, false)), unselectAll: true });
        }
        else {
            this.setState({ checkedUnselected: [], unselectAll: false });
        }
    }

    handleSelectedAll = (e: CheckboxChangeEvent) => {
        let value: Key[];

        if (this.props.value !== undefined)
            value = this.props.value;
        else
            value = this.state.value;

        if (e.target.checked) {
            this.setState({ checkedSelected: this.getLeafKeys(this.parseTreeData(value, true)), selectAll:true });
        }
        else {
            this.setState({ checkedSelected: [], selectAll: false });
        }
    }

    getLeafKeys = (nodes: Array<TreeDataNode>) => {
        const keys: Array<Key> = [];

        for (const node of nodes) {
            if (node.children === undefined) {
                keys.push(node.key);
            }
            else {
                keys.push(...this.getLeafKeys(node.children));
            }
        }

        return keys;
    }

    render() {
        const { className, unselectedTitle, selectedTitle, unselectedEmpty, selectedEmpty, unselectedHeader, selectedHeader, addText, removeText, disabled } = this.props;
        const { checkedUnselected, checkedSelected, expandedUnselected, expandedSelected, unselectAll, selectAll } = this.state;

        let value: Key[];

        if (this.props.value !== undefined)
            value = this.props.value;
        else
            value = this.state.value;

        const unselected = this.parseTreeData(value, false);
        const selected = this.parseTreeData(value, true);

        return (
            <div className={className + " " + styles.treeRow}>
                <div className={styles.treeCol}>
                    <h3 className={styles.subTitle}>{unselectedTitle}</h3>
                    <div className={styles.treeContainer + " " + styles.treeContainerAvailable}>
                        <div className={styles.treeHeader}><Checkbox className={styles.treeHeaderCheck} onChange={this.handleUnselectedAll} checked={unselectAll} />{unselectedHeader}</div>
                        {
                            unselected.length > 0 ?
                                <Tree
                                    checkable={true}
                                    treeData={unselected}
                                    onCheck={this.handleCheckUnselected}
                                    checkedKeys={checkedUnselected}
                                    onExpand={this.handleExpandUnselected}
                                    expandedKeys={expandedUnselected}
                                    className={styles.tree}
                                /> :
                                <Empty description={unselectedEmpty} />
                        }
                    </div>
                </div>
                <div className={styles.treeButtonsCol}>
                    <div>
                        <Button disabled={disabled} type="primary" onClick={this.handleSelect}><MdArrowForward />{addText}</Button>
                        <Button disabled={disabled} danger={true} onClick={this.handleDeselect}><MdArrowBack />{removeText}</Button>
                    </div>
                </div>
                <div className={styles.treeCol}>
                    <h3 className={styles.subTitle}>{selectedTitle}</h3>
                    <div className={styles.treeContainer + " " + styles.treeContainerAssigned}>
                        <div className={styles.treeHeader}><Checkbox className={styles.treeHeaderCheck} onChange={this.handleSelectedAll} checked={selectAll} />{selectedHeader}</div>
                        {
                            selected.length > 0 ?
                                <Tree
                                    checkable={true}
                                    treeData={selected}
                                    onCheck={this.handleCheckSelected}
                                    checkedKeys={checkedSelected}
                                    onExpand={this.handleExpandSelected}
                                    expandedKeys={expandedSelected}
                                    className={styles.tree}
                                /> :
                                <Empty description={selectedEmpty} />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default TreeSelector;
