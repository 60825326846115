// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { Badge, DatePicker, DatePickerProps } from "antd";
import { Dayjs } from "translations";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styles from "./styles/multi-date-picker.less";
import clsx from "clsx";
import { dayjs } from "translations/day-js";

interface Props extends Omit<DatePickerProps, 'onChange' | 'value'>, WithTranslation {
    initialValue?: Array<Dayjs>;
    onChange?: (value: Array<Dayjs>) => void;
    value?: Array<Dayjs> | null;
}

interface State {
    value: Array<Dayjs>;
    displayValue: Dayjs | null;
    open: boolean;
}

class MultiDatePicker extends Component<Props, State> {
    skipOpen: boolean = false;

    constructor(props: Props) {
        super(props);

        const initialValue = props.value ?? (props.initialValue ?? []);

        this.state = {
            value: initialValue,
            displayValue: initialValue.length > 0 ? initialValue[0] : null,
            open: false
        };
    }

    handleCellRender = (current: Dayjs | string | number) => {
        let value: Dayjs[];
        if (this.props.value !== undefined) {
            value = this.props.value ?? [];
        } else {
            value = this.state.value;
        }
        const currentDay = dayjs(current);
        return (
            <div
                onClick={() => this.handleChange(currentDay)}
                className={clsx("ant-picker-cell-inner", {
                    [styles.activeDate]: value.some(v => v.isSame(currentDay, "day"))
                })}
            >
                {currentDay.date()}
            </div>
        )
    }

    handleChange = (newValue: Dayjs | null) => {
        const { onChange } = this.props;
        let value: Dayjs[];

        this.skipOpen = true;

        if (this.props.value !== undefined) {
            value = this.props.value ?? [];
        }
        else {
            value = this.state.value;
        }

        const valueFormat = newValue?.format();

        if (valueFormat === undefined)
        {
            this.setState({ value: [], displayValue: null });

            if (onChange !== undefined)
                onChange([]);
        }
        else if (!value.map(v => v.format()).includes(valueFormat) && newValue != null) {
            value.push(newValue);
            value.sort((a, b) => a.isBefore(b) ? -1 : 1);

            this.setState({ value, displayValue: newValue });

            if (onChange !== undefined)
                onChange(value);
        }
        else {
            let index = value.map(v => v.format()).indexOf(valueFormat);

            if (index > -1)
                value.splice(index, 1);

            this.setState({ value, displayValue: value.length > 0 ? newValue : null });

            if (onChange !== undefined)
                onChange(value);
        }
    }

    handleOpenChange = (e: boolean) => {
        if (!this.skipOpen)
            this.setState({open: e});
        else
            this.skipOpen = false;
    }

    render() {
        const { t, value, onChange, ...other } = this.props;
        const bindValue = value ?? this.state.value;
        const { open, displayValue } = this.state;

        return (
            <DatePicker {...other}
                cellRender={this.handleCellRender}
                onChange={(date) => !date && this.handleChange(null)}
                value={displayValue}
                open={open}
                onOpenChange={this.handleOpenChange}
                suffixIcon={
                    <Badge>{bindValue.length == 1 ? t("oneDateSelected") : t("nDatesSelected", { n: bindValue.length })}</Badge>
                }
                popupClassName={styles.popupCal}
                className={styles.cal}
            />
        );
    }
}
export default withTranslation(["ui"])(MultiDatePicker);