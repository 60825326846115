import Icon from "@ant-design/icons";
import { Col, Row, Select, Tag } from "antd";
import { LabeledValue } from "antd/es/tree-select";
import React, { Component } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { ComparisonType } from "web-service";
import { filterConstants, FilterInputProps, FilterValueSelect } from "../data";
import FilterListInputLabel from "./filter-list-input-label";
import { IoClose } from "react-icons/io5";
import styles from "./styles/filter-list-select.module.less";

interface Props extends FilterInputProps<React.Key> {
    mode?: 'multiple' | 'tags'
    placeholder?: React.ReactNode;
    dropdownOptions: LabeledValue[];
    table?: boolean;
}

interface State {
    visible: boolean;
}

class FilterListSelect extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { values, showVisibilityToggle } = props;

        this.state = {
            visible: (showVisibilityToggle === undefined || showVisibilityToggle === false || (showVisibilityToggle && values !== undefined && values.length > 0))
        };
    }

    /**
     * Process input on change value
     * @param e Input value
     */
    handleChange = (e: React.Key | React.Key[]) => {
        const { filter, dropdownOptions, onChange, defaultComparisonType, values } = this.props;

        const finalValues: FilterValueSelect[] = [];

        const comparison = values && values.length > 0 ? values[0].comparisonType : defaultComparisonType ?? ComparisonType.Equal;

        dropdownOptions
            .filter(o => Array.isArray(e) ? e.includes(o.value) : e === o.value)
            .map((o) => {
                const newValue = new FilterValueSelect();
                newValue.assign(o.label?.toString() ?? "", o.value, undefined, comparison);
                finalValues.push(newValue);
            });
        if (onChange && filter)
            onChange(filter, finalValues);
    };

    handleVisibilityChange = () => {
        const { visible } = this.state;

        const newVisible = !visible;

        if (!newVisible)
            this.handleChange([]);

        this.setState({ visible: newVisible });
    }

    render() {
        const { values, dropdownOptions, mode, disabled, placeholder, table } = this.props;
        const { visible } = this.state;

        const trueValue = values ?? [];

        return (
            <Row align="middle">
                <FilterListInputLabel<React.Key>
                    {...this.props}
                    open={visible || trueValue.length > 0}
                    onOpenChange={this.handleVisibilityChange}
                    options={filterConstants.selectOptions}
                    table={table}
                />
                {
                    (visible || trueValue.length > 0) &&
                    <Col span={24}>
                        <Select
                            suffixIcon={<Icon component={IoIosArrowDown} />}
                            size="middle"
                            mode={mode}
                            disabled={disabled}
                            style={{ width: "100%" }}
                            placeholder={placeholder}
                            defaultActiveFirstOption={false}
                            filterOption={false}
                            value={trueValue.filter(v => v.value !== undefined).map(v => v.value!)}
                            onChange={this.handleChange}
                            options={dropdownOptions}
                            tagRender={(props) => <Tag className={styles.tag} onClose={props.onClose} closeIcon={<Icon className={styles.tagClose} component={IoClose} />} closable>{props.label}</Tag>}
                        />
                    </Col>
                }
            </Row>
        );
    }
}

export default FilterListSelect;