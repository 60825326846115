import React, { Component, ReactNode } from 'react';
import styles from './styles/group-highlight.less';

interface Props {
    className?: string;
    children?: ReactNode;
}

class GroupHighlight extends Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        const { className, children } = this.props;

        return (
            <div className={(className || '') + " " + styles.box}>
                {children ? (
                    <div className={styles.content}>
                        {children}
                    </div>) : ""}
            </div>
        );
    }
}

export default GroupHighlight
