import { Button, Card, Col, List, Row } from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";
import { cloneDeep } from "lodash";
import React, { Component, ReactElement } from "react";
import { i18next } from "translations";
import { elementToInputPropMap, FilterBaseProps, filterConstants, FilterInputProps, FilterValue, IDynamicObject, mapToObject, objectToMap } from "./data";
import styles from "./styles/filter.module.less";

i18next.setDefaultNamespace("ui");

interface Props<T> {
    dropdownProps: FilterDropdownProps;
    label: React.ReactNode;
    header?: ReactElement;
    value: T;
    defaultValue: T;    // Used for re-building the T value and preserving the methods that may be available within
    children: ReactElement[] | ReactElement;
    hideValues?: boolean;
    drawerHeader?: ReactElement[] | ReactElement;

    onChange: (filters: T) => void;
}

interface State<T> {
    keys: string[];
    pendingValue: T;
    valueMap: Map<string, FilterValue<any>[]>;
    oldValueMap: Map<string, FilterValue<any>[]>;
    propMap: Map<string, FilterInputProps<T>>;
    hasChanges: boolean;
}

/**
 * Component for viewing a Filter as a popup. 
 * All changes are not immediately applied.
 * */
class FilterPopup<T extends IDynamicObject<K>, K> extends Component<Props<T>, State<T>> {

    constructor(props: Props<T>) {
        super(props);

        const { value, defaultValue, children } = props;

        const valueMap = objectToMap(value ?? defaultValue);

        this.state = {
            keys: Object.keys(value ?? defaultValue),
            valueMap,
            oldValueMap: cloneDeep(valueMap),
            propMap: this.getPropMap(children),
            pendingValue: cloneDeep(value),
            hasChanges: false
        };
    }

    componentDidUpdate = (prevProps: Props<T>) => {
        const { value, children } = this.props;

        if (value !== prevProps.value) {
            const valueMap = objectToMap(value);
            this.setState({
                pendingValue: cloneDeep(value),
                valueMap,
                oldValueMap: cloneDeep(valueMap),
                keys: Object.keys(value),
                hasChanges: false
            });

        }

        if (children !== prevProps.children) {
            this.setState({
                propMap: this.getPropMap(children)
            });
        }
    };

    getPropMap = (children: ReactElement[] | ReactElement) => {
        const propMap: Map<string, FilterInputProps<any>> = new Map();
        elementToInputPropMap(Array.isArray(children) ? children : [children]).forEach((c) => {
            if (c.bundleKeys) {
                const filterName = (c.filter ?? "").split(filterConstants.filterSeparator);
                filterName.forEach((name) => propMap.set(name, c));
            }
            else {
                propMap.set(c.filter ?? "", c);
            }
        });

        return propMap;
    };

    /**
     * Resets all filter values
     * */
    handleReset = (value: T) => {
        const { dropdownProps } = this.props;

        this.setState({
            pendingValue: cloneDeep(value),
            valueMap: new Map(),
            hasChanges: false
        }, () => {
            this.handleUpdatePendingValue();
            this.handleApplyChange();

            if (dropdownProps && dropdownProps.clearFilters)
                dropdownProps.clearFilters();
        });
    };

    /**
     * Triggers the onChange
     * */
    handleApplyChange = () => {
        const { onChange, dropdownProps } = this.props;
        const { pendingValue } = this.state;

        this.setState({
            hasChanges: false
        }, () => {
            onChange(pendingValue);

            if (dropdownProps && dropdownProps.confirm)
                dropdownProps.confirm({ closeDropdown: true });
        });
    };

    /**
     * Handles addition
     * @param key
     * @param defaultValue
     */
    handleAdd = (key: string, defaultValue?: FilterValue<any>) => {
        let { valueMap, hasChanges } = this.state;

        const keyValues = valueMap.get(key) ?? [];
        const finalValue = cloneDeep(defaultValue) ?? new FilterValue();

        if (finalValue.value !== undefined && finalValue.value !== null)
            hasChanges = true;

        if (keyValues) {
            keyValues.push(finalValue);

            valueMap.set(key, keyValues);

            this.setState({
                valueMap,
                hasChanges
            }, this.handleUpdatePendingValue);
        }
    };

    /**
     * Updates Filter values
     * @param key
     * @param values
     */
    handleChange = (key: string, values: FilterValue<any>[]) => {
        const { valueMap } = this.state;

        valueMap.set(key, cloneDeep(values));

        this.setState({
            valueMap,
            hasChanges: true
        }, this.handleUpdatePendingValue);
    };

    /**
     * Deletes a filter
     * @param key
     * @param index
     * @param doApply
     */
    handleDelete = (key: string, index: number, doApply?: boolean) => {
        const { valueMap } = this.state;

        const keyValues = valueMap.get(key) ?? [];

        if (keyValues) {
            let changed = false;

            if (keyValues.length > index) {
                keyValues.splice(index, 1);
                changed = true;
            }
            else if (index === -1) {
                // Delete all
                valueMap.set(key, []);

                changed = true;
            }

            if (changed) {
                this.setState({
                    valueMap,
                    hasChanges: true
                }, () => {
                    this.handleUpdatePendingValue(() => {
                        if (doApply)
                            this.handleApplyChange();
                    });
                });
            }

        }
    };

    /**
     * Updates Pending Value
     * @param callback
     */
    handleUpdatePendingValue = (callback?: () => void) => {
        const { defaultValue } = this.props;
        const { valueMap } = this.state;
        this.setState({
            pendingValue: mapToObject<T, K>(cloneDeep(defaultValue), valueMap)
        }, callback);
    };

    render() {
        const { dropdownProps, children, defaultValue, drawerHeader } = this.props;
        const { valueMap } = this.state;

        return (
            dropdownProps.visible &&
            <Card
                className={styles.filterPopup}
                style={{ boxShadow: "0px 6px 16px 0px rgba(48, 48, 48, 0.12)" }}
                bordered={false}
                styles={{ body: { padding: 16, width: 360 } }}
            >
                {drawerHeader}
                <List
                    itemLayout={"vertical"}
                    className={styles.filterList}
                    style={{ marginTop: drawerHeader ? 16 : 0 }}
                    footer={
                        <Row justify="space-between" align="middle">
                            <Col>
                                <Button style={{ padding: "0" }} size="small" type="link" onClick={() => this.handleReset(defaultValue)}>{i18next.t("ui:removeFilter").toString()}</Button>
                            </Col>
                            <Col>
                                <Button size="small" type="primary" onClick={this.handleApplyChange}>{i18next.t("ui:apply").toString()}</Button>
                            </Col>
                        </Row>
                    }
                >
                    {children &&
                        (Array.isArray(children) ? children : [children])
                            .map((c: ReactElement, ix) => {
                                const props = c.props as FilterBaseProps<any>;
                                let values: FilterValue<any>[] = [];

                                if (props.filter) {
                                    if (props.filter.includes(filterConstants.filterSeparator)) {
                                        const allFilters = props.filter.split(filterConstants.filterSeparator);
                                        allFilters.forEach((f) => {
                                            values.push(...(valueMap.get(f) ?? []));
                                        });
                                    }
                                    else {
                                        values = valueMap.get(props.filter) ?? [];
                                    }

                                    return <List.Item className={styles.filterItem} key={ix} {...ix === 0 && { style: { paddingTop: 0 } }}>
                                        {
                                            React.cloneElement(c, {
                                                ...props,
                                                values,
                                                onAdd: this.handleAdd,
                                                onChange: this.handleChange,
                                                onRemove: this.handleDelete,
                                                valueMap,
                                                showTitle: true
                                            })
                                        }
                                    </List.Item>;
                                }

                                return (
                                    React.cloneElement(c, {
                                        key: ix
                                    })
                                );
                            })
                    }
                </List>
            </Card>
        );
    }
}

export default FilterPopup;